import React, { useState } from "react"
import Layout from "components/layout"

import { Formik, Form } from "formik"
import * as Yup from "yup"

import { Input, DatePicker, Radio } from "components/anti/forms/forms"
import { Button } from "components/anti/buttons/buttons"
import moment from "moment"

const MammogramFormPage = () => {
  const [dateOfBirth, setDateOfBirth] = useState(null)

  const initialValues = {
    name: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Masukkan Nama Anda"),
  })

  const handleSubmit = data => {
    console.log("data", data)
  }

  return (
    <Layout>
      <section className="sc-mammogram-form py-main">
        <div className="container">
          <h1>Detail Pasien</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, touched, errors }) => (
              <Form>
                <div className="form-row row-5">
                  <div className="col-12">
                    <Radio
                      formik
                      name="packetfor"
                      label="Tambahkan saya sebagai pasien"
                      value="self"
                      className="py-3"
                    />
                  </div>
                  <div className="col-12">
                    <Radio
                      formik
                      name="packetfor"
                      label="Saya membeli paket ini untuk orang lain"
                      value="other"
                      className="py-3"
                    />
                  </div>
                  <div className="col-12 py-4">
                    <h6>
                      Isilah data di bawah ini dengan indentitas pasien yang
                      akan melakukan mammogram:
                    </h6>
                  </div>
                  <div className="form-group col-md-6">
                    <Input
                      floatingLabel
                      variant={"underline"}
                      label={"Nama"}
                      as="input"
                      id="name"
                      name="name"
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Input
                      floatingLabel
                      variant={"underline"}
                      label={"Nomor Handphone"}
                      as="input"
                      id="phoneNumber"
                      name="phoneNumber"
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <div className="field field-underline">
                      <label htmlFor="date">Tanggal Lahir</label>
                      <div className="form-control-wrapper form-date">
                        <DatePicker
                          selected={dateOfBirth}
                          onChange={date => setDateOfBirth(date)}
                          placeholder="Tanggal"
                          className="date-border"
                          name="dateOfBirth"
                          touched={touched}
                          errors={errors}
                          maxDate={moment().toDate()}
                          showYearDropdown
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <Input
                      floatingLabel
                      variant={"underline"}
                      label={"Alamat Email"}
                      as="input"
                      type="email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Input
                      floatingLabel
                      variant={"underline"}
                      label={"Kode Referal"}
                      as="input"
                      id="code"
                      name="code"
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6"></div>
                  <div className="form-group col-md-6">
                    <Button variant="primary" className="btn-block">
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </Layout>
  )
}

export default MammogramFormPage
